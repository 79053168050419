import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationms from './locales/ms.json';

import translationzhCN from './locales/zh-CN.json';

import translationen from './locales/en.json';



i18n

.use(Backend)

.use(LanguageDetector)

.use(initReactI18next)

.init({
lng: 'en',
fallbackLng: 'en',
debug: false,

interpolation: {
escapeValue: false,
},
resources: {

"ms": {
translation: translationms
},

"zh-CN": {
translation: translationzhCN
},

"en": {
translation: translationen
},



}
});


export default i18n;